import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import WebStoriesIcon from "@mui/icons-material/WebStories";
import GroupIcon from "@mui/icons-material/Group";
import useStyles from "../dashAuction.style";
import eventDetailsAPI from "../../../../api/adminAPI/eventDetailsAPI";
import Typography from "@mui/material/Typography";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getTimeZone, localTimeZone } from "../../../../common";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import "react-data-table-component-extensions/dist/index.css";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { toast } from "react-toastify";
import lodash from "lodash";
import { FaRegFileExcel } from "react-icons/fa";
import * as XLSX from "xlsx-js-style";

import deleteBidAPI from "../../../../api/deleteBidAPI";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import getEventReportData from "../../../../api/getEventReportData";

const AuctionView = ({
  Bidlog,
  BidlogSecond,
  eventId,
  AuctionTable,
  changeView,
}) => {
  const classes = useStyles();
  const [tableEventItemsData, setTableEventItemsData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [deletingBid, setDeletingBid] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteBidDetails, setDeleteBidDetails] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fetchingReportLoader, setFetchingReportLoader] = useState(false);

  useEffect(() => {
    eventsDetails(eventId);
    return () => {
      return false;
    };
  }, [eventId]);

  const getZone = getTimeZone() ? getTimeZone() : localTimeZone();

  const handleClick = (event, eventId) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // setEventId("");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const eventsDetails = async (eventId) => {
    try {
      const { data } = await eventDetailsAPI(eventId);

      if (data) {
        let id = 1;

        const currentDate = moment();
        const auctionEndDate = moment(data.bidEndDateTime).add(48, "hours"); // auction end date + 48 hours
        const auctionEnded = auctionEndDate.isBefore(currentDate);
        const eventsData = data.eventItems.map((eventItem, index) => {
          return {
            id: id++,
            lotNumber: eventItem.lotNumber,
            shortDescription: eventItem.shortDescription,
            quantity: eventItem.quantity,
            unit: eventItem.unit,
            startPrice: eventItem.startPrice,
            increment: eventItem.increment,
            participants: eventItem.participants,
            totalBidders: eventItem.totalBidders,
            totalBids: eventItem.totalBids,
            highestBid: eventItem.highestBid,
            highestBidder: eventItem.highestBidder,
            highestBidId: eventItem.highestBidId,
            secondHighestBid: eventItem.secondHighestBid,
            buyers: eventItem.buyers,
            startDateAndTime: moment(eventItem.startDateAndTime)
              .tz(getZone)
              .format("DD/MM/YYYY hh:mm:ss"),
            endDateAndTime: moment(eventItem.endDateAndTime)
              .tz(getZone)
              .format("DD/MM/YYYY hh:mm:ss"),
            action: "hello",
            eventItemId: eventItem.eventItemId,
            auctionEnded,
          };
        });

        setTableEventItemsData(eventsData);
        setEventData(data);
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteBidHandler = async (data) => {
    const details = {
      eventItemId: data.eventItemId,
      bidId: data.highestBidId,
    };
    setDeleteBidDetails(details);
    setDeleteDialogOpen(true);
  };

  const generateBidderWiseReport1 = (
    currency,
    buyers,
    allotedLots,
    biddedLots,
    auctionTotalH1L1BidAmountSum,
    auctionTotalH2L2BidAmountSum,
    auctionTotalUnrankedBidAmountSum,
    auctionTotalH1L1LotCount,
    auctionTotalH2L2LotCount,
    auctionTotalUnrankedLotCount
  ) => {
    // bidder wise detail report 1
    return [
      [
        "Bidder Details",
        "Mail ID",
        "Phone No",
        "Contact person",
        "Allotted Lots",
        "Bidded Lots",
        "H1/L1",
        "Amount (Total H1/L1 amount)",
        "H2/L2",
        "Amount (Total H2/L2 amount)",
        "Other",
        `Amount(${currency})`,
        "% of Lead Amount",
      ],
      [
        `Total Bidders: ${Object.keys(buyers).length} Nos`,
        "",
        "",
        "",
        allotedLots,
        biddedLots,
        auctionTotalH1L1LotCount,
        auctionTotalH1L1BidAmountSum,
        auctionTotalH2L2LotCount,
        auctionTotalH2L2BidAmountSum,
        auctionTotalUnrankedLotCount,
        auctionTotalUnrankedBidAmountSum,
        "100%",
      ],
      ...Object.values(buyers).map((buyer, index) => {
        return [
          `${index + 1}. ${buyer.firstName} ${buyer.lastName}`,
          `${buyer.email}`,
          `${buyer.contactNumber}`,
          `${buyer.alternateContactNumber || ""}`,
          buyer.assignedLots,
          buyer.participatedLots,
          buyer.h1l1Count,
          buyer.h1l1AmountSum,
          buyer.h2l2Count,
          buyer.h2l2AmountSum,
          buyer.unrankedLotCount,
          buyer.unrankedLotAmountSum,
          parseFloat(buyer.leadAmountPercentage).toFixed(2),
        ];
      }),
    ];
  };

  const generateLotWiseReport = (lots, bidders, auctionEventType) => {
    let startBidSum = 0,
      leadAmountSum = 0,
      h2l2AmountSum = 0,
      h3l3AmountSum = 0;

    return [
      [
        "Lot wise details",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [],
      [
        "Lot Number",
        "Description",
        "Start Date/Time",
        "End Date/Time",
        "Unit",
        "Qty",
        "Start Bid",
        auctionEventType === "FORWARD_AUCTION" ? "Increment" : "Decrement",
        "% gain wrt Start bid",
        "Lead Bidder",
        "Lead Rate",
        "Lead amount",
        "H2/L2 Bid",
        "H3/L3 Bid",
        "No of bids",
        "No of Bidders",
        "Participants",
      ],
      ...Object.values(lots).map((lot) => {
        const lotHaveBeenBidden = !!lot.biddingParticipants;
        startBidSum += Number(lot.startPrice || 0);
        leadAmountSum +=
          Number(lot.leadAmount || 0) * (Number(lot.quantity) || 1);
        h2l2AmountSum += Number(lot.h2l2Amount);
        h3l3AmountSum += Number(lot.h3l3Amount);

        return [
          `${lot.lotNumber}`,
          `${lot.longDescription}`,
          `${moment
            .utc(lot.startDateAndTime)
            .local()
            .format("DD/MM/YYYY HH:mm:ss")}`,
          `${moment
            .utc(lot.endDateAndTime)
            .local()
            .format("DD/MM/YYYY HH:mm:ss")}`,
          `${lot.unit}`,
          `${lot.quantity}`,
          lot.startPrice,
          lot.increment,
          lotHaveBeenBidden ? Number(lot.gainWRTStartBid).toFixed(2) : "-",
          lotHaveBeenBidden ? bidders[lot.leadBidder]?.name : "-",
          lotHaveBeenBidden ? Number(lot.leadAmount) : "-", // lead rate
          lotHaveBeenBidden
            ? lot.leadAmount * (Number(lot.quantity) || 1)
            : "-",
          lotHaveBeenBidden ? lot.h2l2Amount : "-",
          lotHaveBeenBidden ? lot.h3l3Amount : "-",
          lotHaveBeenBidden ? lot.totalBids : "-",
          lot.biddingParticipants,
          lotHaveBeenBidden ? lot.assignedBidders : "-",
        ];
      }),
      [
        "",
        "",
        "",
        "",
        "",
        "",
        startBidSum,
        "",
        "",
        "",
        "",
        leadAmountSum,
        h2l2AmountSum,
        h3l3AmountSum,
        "",
        "",
        "",
      ],
    ];
  };

  const generateLotWiseBiddersBidRateReport = (lots, bidders) => {
    const orderedBidders = Object.keys(bidders);

    return [
      [
        "",
        "",
        "",
        "",
        "Bid",
        ...Array.from({ length: Object.keys(bidders).length - 1 }).map(
          (_) => ""
        ),
      ],
      [],
      [
        "Lot Number",
        "Description",
        "Unit",
        "Qty",
        "Start Bid",
        "Number of bidders",
        "Lead Bid",
        "Lead Bid Amount",
        "Lead Bidder",
        ...Object.values(bidders).map((bidder, index) => {
          return `${index + 1}. ${bidder.firstName} ${bidder.lastName}`;
        }),
      ],
      ...Object.values(lots).map((lot) => {
        const lotHaveBeenBidden = !!lot.biddingParticipants;

        // for this lot get ordered bidder lot list
        const bidRateOrdered = orderedBidders.map((bidderId) => {
          return lot.biddersBidRate.find(
            (bidder) => bidder.bidderId === bidderId
          );
        });

        return [
          `${lot.lotNumber}`,
          `${lot.longDescription}`,
          `${lot.unit}`,
          `${lot.quantity}`,
          `${lot.startPrice}`,
          `${lot.biddingParticipants || 0}`,
          lotHaveBeenBidden ? Number(lot.leadAmount) : "-", // lead rate
          lotHaveBeenBidden
            ? lot.leadAmount * (Number(lot.quantity) || 1)
            : "-",
          lotHaveBeenBidden ? bidders[lot.leadBidder].name : "-",
          ...bidRateOrdered.map((bidRate) => {
            return bidRate?.bidRate || "-";
          }),
        ];
      }),
    ];
  };

  const generateAuctionBriefSummary = (
    auctionPublishDateTime,
    auctionStartDateTime,
    auctionBidEndDateTime,
    auctionPreBid,
    totalLots,
    totalBidders,
    allotedLots,
    allotedBidders,
    biddedLots,
    totalBiddingParticipants,
    totalPrebidsSum,
    totalLeadAmountValueOfAllLots,
    totalStartPriceSum,
    startTime,
    endTime
  ) => {
    return [
      [
        "Auction brief summary",
        "",
        "",
        "Auction Details: ",
        "",
        "Date And Time",
        "",
        "Lot",
      ], // Heading
      [
        "Description",
        "Lots",
        "Bidders",
        "Event Total Amount",
        "",
        "Publish Date/Time",
        `${moment
          .utc(auctionPublishDateTime)
          .local()
          .format("DD/MM/YYYY HH:mm:ss")}`,
        `Prebid: ${auctionPreBid}`,
      ],
      [
        "Total",
        totalLots,
        totalBidders,
        "Start Bid Value",
        totalStartPriceSum
          ? parseFloat(`${totalStartPriceSum}`).toFixed(2)
          : "-",
        "Start Date and TIme",
        `${moment
          .utc(auctionStartDateTime)
          .local()
          .format("DD/MM/YYYY HH:mm:ss")}`,
        `${moment.utc(startTime).local().format("DD/MM/YYYY HH:mm:ss")}`,
        "",
        "",
      ],
      [
        "Alloted",
        allotedLots,
        allotedBidders,
        "Pre Bid Value:",
        totalPrebidsSum ? parseFloat(`${totalPrebidsSum}`).toFixed(2) : "-",
        "End Date and TIme",
        `${moment
          .utc(auctionBidEndDateTime)
          .local()
          .format("DD/MM/YYYY HH:mm:ss")}`,
        `${moment.utc(endTime).local().format("DD/MM/YYYY HH:mm:ss")}`,
        "",
        "",
      ],
      [
        "Bidded",
        biddedLots,
        totalBiddingParticipants,
        "Lead Value",
        totalLeadAmountValueOfAllLots
          ? parseFloat(`${totalLeadAmountValueOfAllLots}`).toFixed(2)
          : "-",
        "Extended Date and Time",
        `${moment
          .utc(auctionBidEndDateTime)
          .local()
          .format("DD/MM/YYYY HH:mm:ss")}`,
        `${moment.utc(endTime).local().format("DD/MM/YYYY HH:mm:ss")}`,
      ],
      [], // Blank row
    ];
  };

  const generateBidderLotMappedReport = (
    lots,
    bidders,
    biddersFinalBidsWithRank,
    auctionEventType
  ) => {
    return [
      [
        "Bidder",
        "Lot No.",
        "Description",
        "Quantity & Unit",
        "Final Bid Amount",
        "Position",
        "% Gain wrt Start",
        "Start Bid",
        auctionEventType === "FORWARD_AUCTION" ? "Increment" : "Decrement",
        "No of Bids",
        "Bidders",
        "Participants",
      ], // Heading
      ...biddersFinalBidsWithRank.map((bidderRank) => {
        const lot = lots[bidderRank.eventItemId];
        console.log("LOT: ", lot);
        return [
          bidders[bidderRank.userId].name,
          bidderRank.lotNumber,
          lot.shortDescription || "",
          `${lot.quantity} ${lot.unit}`,
          bidderRank.finalBid || "",
          bidderRank.rank,
          bidderRank.gainPercWRTStartBid,
          bidderRank.startBid,
          bidderRank.increment,
          lot.userBidsCount[bidderRank.userId] || 0,
          lot.biddingParticipants,
          lot.assignedBidders,
        ];
      }),
      [], // Blank row
    ];
  };

  const boldStyle = { font: { bold: true } };

  const boldRow = (ws, rowNumber) => {
    const startCol = "A";
    const endCol = "Z"; // Adjust this based on how many columns you have

    // Iterate over each column in the first row
    for (let col = startCol.charCodeAt(0); col <= endCol.charCodeAt(0); col++) {
      // Construct the cell reference (e.g., A1, B1, ..., Z1)
      const cellRef = String.fromCharCode(col) + rowNumber;

      // Check if the cell exists before applying the style
      if (ws[cellRef]) {
        ws[cellRef].s = boldStyle;
      }
    }

    return ws;
  };

  const setBackgroundColorForRow = (worksheet, rowNum, color) => {
    // Assuming the row number (rowNum) is 1-based and the column letters A, B, C, ...
    const range = XLSX.utils.decode_range(worksheet["!ref"]); // Get the sheet range
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = { c: C, r: rowNum - 1 }; // Convert 1-based rowNum to 0-based
      const cellRef = XLSX.utils.encode_cell(cellAddress);

      if (!worksheet[cellRef]) {
        worksheet[cellRef] = { t: "s", v: "" }; // Create an empty string cell if it doesn't exist
      }

      if (!worksheet[cellRef].s) {
        worksheet[cellRef].s = {};
      }

      // Set the background color
      worksheet[cellRef].s.fill = {
        patternType: "solid",
        fgColor: { rgb: "#cacaca" },
      };
    }
  };

  const generateExcel = (reportData) => {
    const wb = XLSX.utils.book_new();

    const {
      auctioneer,
      partnerDetails,
      totalLots,
      totalBidders,
      allotedLots,
      allotedBidders,
      biddedLots,
      totalBiddingParticipants,
      totalPrebidsSum,
      totalLeadAmountOfAuction,
      totalLeadAmountValueOfAllLots,
      totalBidsSum,
      totalStartPriceSum,
      auctionPublishDateTime,
      auctionStartDateTime,
      auctionBidEndDateTime,
      auctionExtendedDateTime,
      currency,
      auctionPreBid,
      auctionOwnerOfLots,
      startTime, // start time of lot which started first from this auction
      endTime, // end time of lot which ended in last from this auction
      auctionId,
      auctionReferenceNumber,
      auctionTitle,
      auctionDescription,
      auctionEventType,
      auctionEventMessage,
      auctionCreatedAt,
      bidders,
      lots,
      latestBidAmountsLotWise,
      auctionTotalH1L1BidAmountSum,
      auctionTotalH2L2BidAmountSum,
      auctionTotalUnrankedBidAmountSum,
      auctionTotalH1L1LotCount,
      auctionTotalH2L2LotCount,
      auctionTotalUnrankedLotCount,
      biddersFinalBidsWithRank,
    } = reportData;

    // Heading for Sheet
    const headingSheet = [
      [`Auction Report for ${auctioneer.firstName} ${auctioneer.lastName}`],
      [],
      [
        `Auction ID: ${auctionId}`,
        `dated ${moment
          .utc(auctionCreatedAt)
          .local()
          .format("DD MM, YYYY  HH:mm:ss")}`,
      ],
      [
        `Auction Conducted by: ${auctioneer.firstName} ${auctioneer.lastName}, ${partnerDetails.companyName}`,
        `for the lots of ${auctionOwnerOfLots}`,
      ],
      [
        `Auction Type: ${auctionEventType?.replace("_", " ")}`,
        `Pre Bid:  ${auctionPreBid}`,
      ],
      ["Auction: ", `${auctionTitle}`, `${auctionDescription}`],
      [],
      ["Auction Message:", `${auctionEventMessage}`],
      [], // Blank row
      ...generateAuctionBriefSummary(
        auctionPublishDateTime,
        auctionStartDateTime,
        auctionBidEndDateTime,
        auctionPreBid,
        totalLots,
        totalBidders,
        allotedLots,
        allotedBidders,
        biddedLots,
        totalBiddingParticipants,
        totalPrebidsSum,
        totalLeadAmountValueOfAllLots,
        totalStartPriceSum,
        startTime,
        endTime
      ),
      ...generateLotWiseBiddersBidRateReport(lots, bidders),
      [],
      ...generateLotWiseReport(lots, bidders, auctionEventType),
      [],
      ...generateBidderWiseReport1(
        currency,
        bidders,
        allotedLots,
        biddedLots,
        auctionTotalH1L1BidAmountSum,
        auctionTotalH2L2BidAmountSum,
        auctionTotalUnrankedBidAmountSum,
        auctionTotalH1L1LotCount,
        auctionTotalH2L2LotCount,
        auctionTotalUnrankedLotCount
      ),
      [],
      ...generateBidderLotMappedReport(
        lots,
        bidders,
        biddersFinalBidsWithRank,
        auctionEventType
      ),
    ];

    // Convert the data to a single sheet
    let ws = XLSX.utils.aoa_to_sheet(headingSheet);

    ws["A1"].s = {
      font: {
        sz: 14, // Font size
        bold: true, // Bold font
        // color: { rgb: "FF0000" } // You can also specify font color etc.
      },
    };

    // Set the height of a specific row, e.g., the first row (index 0)
    if (!ws["!rows"]) ws["!rows"] = []; // Initialize if not already
    ws["!rows"][0] = { hpt: 40 }; // Set the row height to 40 points
    ws["!rows"][10] = { hpt: 20 }; // Set the row height to 40 points

    const rowsToBold = [
      1,
      10,
      11,
      16,
      18,
      20 + totalLots,
      22 + totalLots,
      24 + 2 * totalLots,
      25 + 2 * totalLots,
      28 + 2 * totalLots + totalBidders,
    ];

    rowsToBold.forEach((rtb) => {
      ws = boldRow(ws, rtb);
    });

    setBackgroundColorForRow(ws, 1, "#fafafa");

    ws["!cols"] = Array.from({ length: 26 }, () => ({ wch: 40 })); // Setting column width
    ws["!merges"] = [
      { s: { c: 0, r: 0 }, e: { c: 4, r: 0 } },
      { s: { c: 0, r: 9 }, e: { c: 2, r: 9 } },
      { s: { c: 3, r: 9 }, e: { c: 4, r: 9 } },
      { s: { c: 5, r: 9 }, e: { c: 6, r: 9 } },
      { s: { c: 0, r: 15 }, e: { c: 2, r: 15 } },
      { s: { c: 4, r: 19 + totalLots }, e: { c: 7, r: 19 + totalLots } },
    ];

    // Applying center alignment to all cells
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = { r: R, c: C };
        const cellRef = XLSX.utils.encode_cell(cellAddress);
        const cell = ws[cellRef];
        if (cell) {
          if (!cell.s) cell.s = {};
          cell.s.alignment = { horizontal: "center", vertical: "center" };
        }
      }
    }

    // Add the sheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Auction Report");

    // Save the Excel file
    XLSX.writeFile(wb, `report-${auctionTitle}.xlsx`);
  };

  const downloadReport = async () => {
    setFetchingReportLoader(true);
    const fetchedDetails = await getEventReportData(eventId);

    generateExcel(fetchedDetails.data);
    setFetchingReportLoader(false);
  };

  const columns = [
    {
      name: "Auction ID",
      cell: (data) => String(data.auctionId),
      sortable: true,
      width: "150px",
    },
    {
      name: "Description",
      cell: (data) => {
        return (
          <Tooltip
            title={data.shortDesc}
            placement="top-start"
            TransitionComponent={Zoom}
          >
            <span className={classes.ellipsisText}>{data.shortDesc}</span>
          </Tooltip>
        );
      },
      sortable: false,
      width: "180px",
    },
    {
      name: "Start Date & Time",
      cell: (data) => String(data.startDT),
      sortable: false,
      width: "180px",
    },
    {
      name: "End Date & Time",
      cell: (data) => String(data.endDT),
      sortable: false,
      width: "180px",
    },
    {
      name: "No. of Lots",
      cell: (data) => String(data.numLot),
      sortable: false,
    },
    {
      name: "Participants",
      cell: (data) => String(data.participant),
      sortable: false,
      width: "120px",
    },
    {
      name: "Bidder",
      cell: (data) => String(data.bidder),
      sortable: false,
    },
    {
      name: "UnBidder",
      cell: (data) => String(data.unBidder),
      sortable: false,
    },
    {
      name: "Action",
      cell: (data) => (
        <div>
          <Button
            aria-describedby={data.eventId}
            variant="contained"
            onClick={(event) => {
              handleClick(event, data.eventId);
            }}
          >
            <MoreVertIcon />
          </Button>
          <Popover
            id={data.eventId}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography sx={{ p: 1, display: "grid", px: 2 }}>
              <div
                className={classes.viewBtn}
                onClick={() => {
                  Bidlog(data.eventId);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <GroupIcon
                  style={{
                    color: "#2D55EB",
                    paddingRight: "5px",
                    marginRight: ".4rem",
                  }}
                />
                Buyers
              </div>
              <div
                className={classes.viewBtn}
                onClick={() => changeView("AuctionDetailLogs")}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <WebStoriesIcon
                  style={{
                    color: "#2D55EB",
                    paddingRight: "5px",
                    marginRight: ".4rem",
                  }}
                />
                Logs
              </div>
              <div
                className={classes.viewBtn}
                onClick={() => downloadReport()}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {fetchingReportLoader ? (
                  <CircularProgress
                    size={"1.1rem"}
                    style={{
                      marginRight: ".6rem",
                    }}
                  />
                ) : (
                  <FaRegFileExcel
                    style={{
                      fontSize: "25px",
                      color: "#04aa18",
                      paddingTop: "4px",
                      marginRight: ".4rem",
                      marginLeft: "-.2rem",
                    }}
                  />
                )}
                Download Report
              </div>
            </Typography>
          </Popover>
        </div>
      ),
    },
  ];

  const nextColumns = [
    {
      name: "Sr.",
      cell: (data) => String(data.id),
      sortable: true,
      width: "80px",
    },
    {
      name: "Lot No",
      cell: (data) => String(data.lotNumber),
      sortable: true,
      width: "120px",
    },
    {
      name: "Short Description",
      cell: (data) => {
        return (
          <Tooltip
            title={data.shortDescription}
            placement="top-start"
            TransitionComponent={Zoom}
          >
            <span className={classes.ellipsisText}>
              {data.shortDescription}
            </span>
          </Tooltip>
        );
      },
      sortable: true,
      width: "180px",
    },

    {
      name: "Qty",
      cell: (data) => String(data.quantity),
      sortable: true,
    },
    {
      name: "Unit",
      cell: (data) => String(data.unit),
      sortable: true,
    },
    {
      name: `Start Price ${eventData.currency}`,
      cell: (data) => String(data.startPrice),
      sortable: true,
      width: "150px",
    },
    {
      name: `${
        eventData.eventType === "FORWARD_AUCTION" ? "Increment" : "Decrement"
      } ${eventData.currency}`,
      cell: (data) => String(data.increment),
      sortable: true,
      width: "150px",
    },
    {
      name: "Start Date & Time",
      cell: (data) => String(data.startDateAndTime),
      sortable: true,
      width: "200px",
    },
    {
      name: "End Date & Time",
      cell: (data) => String(data.endDateAndTime),
      sortable: true,
      width: "200px",
    },
    {
      name: "Participant",
      cell: (data) => String(data.participants),
      sortable: true,
      width: "120px",
    },
    {
      name: "Bidder",
      cell: (data) => String(data.totalBidders),
      sortable: true,
    },
    {
      name: "No. of Bids",
      cell: (data) => String(data.totalBids),
      sortable: true,
      width: "120px",
    },
    {
      name: "Lead Bidder",
      cell: (data) => String(data.highestBidder),
      sortable: true,
      width: "120px",
    },
    {
      name: `Lead Bid (${eventData.currency})`,
      cell: (data) => {
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "2rem",
            }}
          >
            {String(data.highestBid || "-")}
            {!data.auctionEnded && (
              <span
                style={{
                  marginLeft: "1rem",
                }}
              >
                <Tooltip title="delete bid">
                  <DeleteOutlinedIcon
                    onClick={() => data.highestBid && deleteBidHandler(data)}
                    style={{
                      fontSize: "1.4rem",
                      color: data.highestBid ? "red" : "grey",
                      cursor: data.highestBid ? "pointer" : "not-allowed",
                    }}
                  />
                </Tooltip>
              </span>
            )}
          </div>
        );
      },
      sortable: true,
      width: "150px",
    },
    {
      name: `Amount(${eventData.currency})`,
      cell: (data) => String(data.secondHighestBid || "-"),
      sortable: true,
      width: "150px",
    },
    {
      name: "Buyer's Bid",
      sortable: true,
      width: "150px",
      cell: (data) => (
        <div>
          {data.buyers.map((buyer, index) => {
            return (
              <Typography sx={{ fontSize: "13px" }}>
                <div>
                  <span className={classes.viewBtn}>
                    {buyer["users.firstName"]}
                    {buyer["users.lastName"]} - {buyer["amount"]} -{" "}
                    {eventData.eventType === "REVERSE_AUCTION"
                      ? `L${index + 2}`
                      : `H${index + 2}`}
                  </span>
                </div>
              </Typography>
            );
          })}
        </div>
      ),
    },
    {
      name: "Action",
      sortable: true,
      cell: (data) => (
        <div>
          <Button
            className="btn btn-group"
            onClick={() => {
              BidlogSecond(data.eventItemId);
            }}
          >
            Bidlog
          </Button>
        </div>
      ),
    },
  ];

  const data = [
    {
      auctionId: eventData.eventNumber,
      shortDesc: eventData.description,
      startDT: moment(eventData.bidStartDateTime)
        .tz(getZone)
        .format("DD/MM/YYYY hh:mm:ss"),
      endDT: moment(eventData.bidEndDateTime)
        .tz(getZone)
        .format("DD/MM/YYYY hh:mm:ss"),
      numLot: eventData.totalLots,
      participant: eventData.participants,
      bidder: eventData.totalBuyers,
      unBidder: eventData.totalUnBidedLots,
      action: "hello",
    },
  ];

  const handleDeleteConfirmDialogClose = () => {
    setDeleteBidDetails(null);
    setDeleteDialogOpen(false);
  };

  const handleDeleteBidConfirm = async () => {
    if (deleteBidDetails) {
      try {
        const { bidId, eventItemId } = deleteBidDetails;
        setDeletingBid(bidId);
        const deleteBidResponse = await deleteBidAPI({
          bidId,
          eventId,
          eventItemId,
        });

        // if bid delete response is positive
        if (deleteBidResponse.status === 200) {
          toast.info("Bid deleted!");
          eventsDetails(eventId);
        } else {
          toast.error("Bid not deleted. Something went wrong!");
        }
      } catch (error) {
        toast.error("Bid not deleted. Something went wrong!");
      }
      setDeletingBid(null);
    }
    handleDeleteConfirmDialogClose();
  };

  const auctionDetailsExportToXLSX = (tableData, tableHeader) => {
    const headerColumns = lodash.map(tableHeader, ({ name }) => {
      if (name !== "Action") {
        return name;
      }
    });
    const dataColumns = lodash.map(tableData, (columns) => [
      eventData.eventNumber,
      eventData.description,
      moment(eventData.bidStartDateTime)
        .tz(getZone)
        .format("DD/MM/YYYY hh:mm:ss"),
      moment(eventData.bidEndDateTime)
        .tz(getZone)
        .format("DD/MM/YYYY hh:mm:ss"),
      eventData.totalLots,
      eventData.participants,
      eventData.totalBuyers,
      eventData.totalUnBidedLots,
    ]);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headerColumns, ...dataColumns]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "auctions-name.xlsx");
  };

  const auctionLotsListingExportToXLSX = (
    tableData,
    tableHeader,
    auctionData,
    auctionColumns
  ) => {
    // =========== auction Data Columns
    const auctionDeatilsHeaderColumns = lodash.map(
      auctionColumns,
      ({ name }) => {
        if (name !== "Action") {
          return name;
        }
      }
    );
    const auctionDetailsDataColumns = lodash.map(auctionData, (columns) => [
      eventData.eventNumber,
      eventData.description,
      moment(eventData.bidStartDateTime)
        .tz(getZone)
        .format("DD/MM/YYYY hh:mm:ss"),
      moment(eventData.bidEndDateTime)
        .tz(getZone)
        .format("DD/MM/YYYY hh:mm:ss"),
      eventData.totalLots,
      eventData.participants,
      eventData.totalBuyers,
      eventData.totalUnBidedLots,
    ]);

    // ===================== Auction Lots Listing Export to XLSX =====================
    const headerColumns = lodash.map(tableHeader, ({ name }) => {
      if (name !== "Action") {
        return name;
      }
    });
    const dataColumns = lodash.map(tableData, (columns) => {
      columns.auctionBidders = lodash
        .map(columns.buyers, (buyer, index) => {
          return `${buyer["users.firstName"]} - ${buyer["users.lastName"]} - ${
            buyer["amount"]
          } ${
            eventData.eventType === "REVERSE_AUCTION"
              ? `L${index + 2}`
              : `H${index + 2}`
          }`;
        })
        .join("\n");

      return [
        columns.id,
        columns.lotNumber,
        columns.shortDescription,
        columns.quantity,
        columns.unit,
        columns.startPrice,
        columns.increment,
        columns.startDateAndTime,
        columns.endDateAndTime,
        columns.participants,
        columns.totalBidders,
        columns.totalBids,
        columns.highestBidder,
        columns.highestBid,
        columns.secondHighestBid,
        columns.auctionBidders,
      ];
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([
      auctionDeatilsHeaderColumns,
      ...auctionDetailsDataColumns,
      "",
      "",
      headerColumns,
      ...dataColumns,
    ]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "lots-listing.xlsx");
  };

  const AuctionDetailsExportButton = () => (
    <Button onClick={() => auctionDetailsExportToXLSX(data, columns)}>
      <FaRegFileExcel
        style={{ fontSize: "30px", color: "#04aa18", paddingTop: "4px" }}
      />
    </Button>
  );

  const AuctionLotsListingExportButton = () => (
    <Button
      onClick={() =>
        auctionLotsListingExportToXLSX(
          tableEventItemsData,
          nextColumns,
          data,
          columns
        )
      }
    >
      <FaRegFileExcel
        style={{ fontSize: "30px", color: "#04aa18", paddingTop: "4px" }}
      />
    </Button>
  );

  return (
    <>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteConfirmDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Bid?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this bid ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmDialogClose}>Cancel</Button>
          <Button onClick={handleDeleteBidConfirm} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <div className="AuctionTable " source="ADMIN||SELLER">
        <div className="AuctionView">
          <h3>
            <Button sx={{ color: "#000" }} onClick={AuctionTable}>
              <ArrowBackIcon />
            </Button>
            Auctions Name - {eventData.auctionTitle}
          </h3>
          <DataTableExtensions
            print={false}
            export={false}
            exportHeaders={false}
            columns={columns}
            data={data}
          >
            <DataTable
              noHeader={false}
              defaultSortField="id"
              defaultSortAsc={false}
              pagination={false}
              highlightOnHover
              className="DataTable"
              columns={columns}
              data={data}
              actions={<AuctionDetailsExportButton />}
            />
          </DataTableExtensions>
        </div>
      </div>
      <div className="AuctionTable mt-5">
        <h3>Lots Listing</h3>
        <DataTableExtensions
          print={false}
          export={false}
          exportHeaders={false}
          columns={nextColumns}
          data={tableEventItemsData}
        >
          <DataTable
            noHeader={false}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination={false}
            highlightOnHover
            className="DataTable"
            actions={<AuctionLotsListingExportButton />}
          />
        </DataTableExtensions>
      </div>
    </>
  );
};

export default AuctionView;
