import config from "../config/config";
import axios from "axios";
import { getToken } from "../common";

export const getEventDetail = async (request, params = {}) => {
  return await axios({
    method: "get",
    url: `${config.api.base_url}events/${request.auctionId}`,
    params: { ...params },
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: getToken(),
    },
  });
};

export default getEventDetail;
